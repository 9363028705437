<template>
    <div class="pt-4">
        <p class="text-center mb-2">Paga aquí las compras realizadas en nuestros comercios aliados.</p>
        <div class="cont_medios_pago">
            <div class="row m-0 justify-content-center" style="gap: 7px">
                <img src="../assets/comercios/logo-stirpe.svg" alt="logo de stirpe">
                <img src="../assets/comercios/logo-clover.svg" alt="logo de clover">
                <img src="../assets/comercios/logo-derek.svg" alt="logo de derek">
                <img src="../assets/comercios/logo-u82.svg" alt="logo de u82">
                <img src="../assets/comercios/logo-travel.svg" alt="logo de travel">
                <img src="../assets/comercios/logo-equis.svg" alt="logo de equis">
                <img src="../assets/comercios/logo-factory.svg" alt="logo de factory">
                <!-- <img src="../assets/comercios/logo-chevignon.svg" alt="logo de Chevignon" style="max-width: 60px">
                <img src="../assets/comercios/logo-americanino.svg" alt="logo de Americanino" style="max-width: 80px"> -->
                <img src="../assets/comercios/logo-bambinos.svg" alt="logo de bambinos">
                <img src="../assets/comercios/logo-quinta.svg" alt="logo de quinta del mar">
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.cont_medios_pago{
    max-width: 420px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
    align-content: center;
    img {
        margin: .3rem;
        filter: grayscale(1);
    }
}
</style>