<template>
    <div>
        <Layout>
            <div class="container-card-cuota">
                <h2 class="title mb-3">Paga tu cuota</h2>
                <p class="subtitle-container">Podrás gestionar tus pagos y acceder a todos los detalles de tu crédito de
                    manera fácil, rápida y segura.</p>
                <div class="container-form">
                    <label for="type-doc">Seleccione el tipo de documento</label>
                    <select v-model="typeDoc" placeholder="Tipo de documento" name="type-doc" id="type-doc"
                        class="select-quac">
                        <option :value="null">Tipo de documento</option>
                        <option :value="typeDoc.document_type_code" :key="index"
                            v-for="(typeDoc, index) in typeDocuments">
                            {{ typeDoc.document_type_name }}
                        </option>
                    </select>
                    <label for="doc">Ingresa tu número de documento</label>
                    <div class="container-input-doc mb-5">
                        <input id="doc" name="doc" type="number" placeholder="Número de documento" v-model="documento"
                            @blur="validarInputDocumento()" @keyup.enter="validarDocumento()">
                    </div>
                    <p id="message-error" style="display:none;">Por favor ingresa un número de documento válido.</p>
                    <button :disabled="!validateInputs" id="sendSoli" class="button-primary mt-2"
                        @click="validarDocumento()">
                        CONTINUAR
                    </button>
                    <ErrorsApi />
                </div>
                <MediosPago />
            </div>
        </Layout>
    </div>
</template>
<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import ErrorsApi from '../components/ErrorsApi'
import MediosPago from '../components/MediosPago.vue'
import Layout from '../components/layouts/Layout.vue'
import $ from 'jquery'
export default {
    name: 'ValidarDocumento',
    components: {
        ErrorsApi,
        MediosPago,
        Layout,
    },
    data() {
        return {
            documento: '',
            typeDoc: null,
            typeDocuments: [
                {
                    "document_type_code": "CC",
                    "document_type_name": "Cédula de ciudadanía"
                },
                {
                    "document_type_code": "PPT",
                    "document_type_name": "PPT"
                },
                {
                    "document_type_code": "CE",
                    "document_type_name": "Cédula extranjería"
                }
            ],
        }
    },
    computed: {
        ...mapState(['documentoCliente', 'procesoAnterior']),
        validateInputs() {
            return this.documento && this.documento.toString().length > 4 && this.typeDoc != null
        }
    },
    watch: {
        documento() {
            if (this.documento.toString().length > 10) {
                this.documento = this.documento.toString().slice(0, 10)
            }
        },
    },
    mounted() {
        // this.obtenerTiposDeDocumento()
        this.$store.commit('changeViewLayout', true)
        if (this.documentoCliente && this.procesoAnterior == 'estado-cuenta') {
            this.documento = this.documentoCliente;
            this.validarDocumento();
        } else {
            this.resetStore();
        }
    },
    unmounted() {
        this.$store.commit('changeViewLayout', false)
    },
    methods: {
        ...mapMutations(['agregarMessageError', 'resetStore']),
        ...mapActions(['obtenerTiposDeDocumento']),
        validarDocumento() {
            if (this.validateInputs) {
                const elthis = this
                window.grecaptcha.ready(function () {
                    window.grecaptcha.execute('6LfBprgqAAAAACgkVRNuhW7fggjEYnaJKhs7C7IG', { action: 'submit' })
                    .then(function (token) {
                        console.log(token)
                        if (token) {
                            let payload = {
                                doc: elthis.documento.toString(),
                                typeDoc: elthis.typeDoc
                            }
                            return elthis.$store.dispatch('validarDocumento', payload);
                        }
                    });
                });
            } else {
                this.validarInputDocumento()
            }
        },
        validarInputDocumento() {
            if (this.documento && this.documento.toString().length > 4) {
                $("#container-input-doc").removeClass('container-input-doc--sin-borde');
                $("#container-input-doc span").removeClass('input-group-span-error');
                $("#container-input-doc input").removeClass('input-group-input-error');
                $("#message-error").hide();
            } else {
                $("#container-input-doc").addClass('container-input-doc--sin-borde');
                $("#container-input-doc span").addClass('input-group-span-error');
                $("#container-input-doc input").addClass('input-group-input-error');
                $("#message-error").show();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.container-card-cuota {
    text-align: start;

    label {
        color: black;
    }

    .title {
        font-size: 28px;
        font-weight: 800;
        max-width: 350px;
        margin-right: auto;
        margin-left: auto;
        color: #2E1380;
    }

    .subtitle-container {
        color: #7F7FB4;
        text-align: start;
        max-width: 350px;
        margin-right: auto;
        margin-left: auto;
    }
}
</style>